if ('trip' == window.page_name) {
  function tripData() {
    $("#trip-total-price").html('<i class="fa fa-refresh fa-spin"></i>');

    $.ajax({
      type: "GET",
      url: '/trip/'+window.trip_slug+'/data?' + $("#trip-form").serialize(),
      dataType: "json"
    })
    .done(function(data) {
      console.log(data);

      if ('number' == typeof data.total_price) {
        $(".trip-total-price").each(function () {
          $(this).html(data.total_price_format);
        });
        $("#trip-advance-amount").html(data.advance_price_format);
        $(".deposit").html(data.advance_price_format);
        $("#trip-advance-percent").html(data.advance_percent + "%");
      }

      if (data.advance_possible) {
        $('.advance-possible').css('display', 'block');
      } else {
        $('.advance-impossible').css('display', 'block');
      }

      if (data.coupon) {
        if (data.coupon.valid) {
          $('#trip-coupon-form').removeClass('has-error');
          $('#trip-coupon-form').addClass('has-success');
          $('#trip-coupon-error').html('');
          $('#trip-coupon-success').html(data.coupon.success);
          $('#coupon-code').attr('disabled','disabled');
        } else {
          $('#trip-coupon-form').addClass('has-error');
          $('#trip-coupon-form').removeClass('has-success');
          $('#trip-coupon-error').html(data.coupon.error);
          $('#trip-coupon-success').html('');
          $('#coupon-code').removeAttr('disabled');
        }
      } else {
        $('#trip-coupon-form').removeClass('has-error');
        $('#trip-coupon-form').removeClass('has-success');
        $('#trip-coupon-error').html('');
        $('#trip-coupon-success').html('');
        $('#coupon-code').removeAttr('disabled');
      }
    })
    .fail(function(jqXHR, textStatus, errorThrown) {
      console.warn(textStatus);
      console.error(errorThrown);
    })
    .always(function(data, textStatus, errorThrown) {
      console.log('always...');
      console.log(data);
      console.log(textStatus);
      console.log(errorThrown);
    });
  }

  function converOptionDescritpionsText() {
    var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    var exp2 =/(^|[^\/])(www\.[\S]+(\b|$))/gim;

	  $('.trip-option-description').each(function() {
      var text = $(this).html();
      var text1 = text.replace(exp, "<a href='$1'>$1</a>");

      $(this).html(text1.replace(exp2, '$1<a target="_blank" href="http://$2">$2</a>'));
    });
  }

  $(function() {
    $('.trip-option').on('change', tripData);

    $('#trip-coupon-check-btn').on('click', function() {
      if ($('#coupon-code').val().length == 0) {
        alert('Wprowadź kod kuponu!');
      } else {
        tripData();
      }
    });

    $('#trip-coupon-remove-btn').on('click', function() {
      $('#coupon-code').val('');
      $('#coupon-code').attr('disabled','');

      tripData();
    });

    $("#trip-form").validate({
      lang: app_lang,
      submitHandler: function(form) {
          $('#coupon-code').removeAttr('disabled');
          //$("#trip-confirm-btn").prop('disabled','disabled');
          form.submit();
      }
    });

    tripData();
    converOptionDescritpionsText();
  });
}
