
if(window.page_name == 'register'){

  $(document).ready(function(){

    $("#register-form").validate({
      lang: app_lang,
      rules: {
        password: {
          minlength: 6
        },
        password_confirmation: {
          equalTo: "#password",
        },
        phone: {
          minlength: 9
        },
        phone_parent: {
          minlength: 9
        },
      },
      errorPlacement: function(error, element) {
        error.appendTo( element.closest('div') );
      },
      submitHandler: function(form) {
          $("#register-btn").prop('disabled','disabled');
          form.submit();
      }
    });

  });



}
