

if(window.page_name == 'persons'){

    function updatePerson() {
        document.getElementById('button_update_person').style.display = 'none';
        document.getElementById('update_person').value = 1;
        $("input[type=text]").prop('readonly', false);
        $("input[type=number]").prop('readonly', false);
        $("#gender").prop('disabled', false);
        $("input[type=email]").prop('readonly', false);
        $('#address2_same').prop('disabled', false);
    }

    function cleanForm(){
        $("input[type=text]").val('');
        $("input[type=number]").val('');
        $("input[type=email]").val('');
        $("input[type=text]").prop('readonly', false);
        $("input[type=number]").prop('readonly', false);
        $("input[type=email]").prop('readonly', false);
        $("#gender").prop('disabled', false);
        $('#address2_same').prop('disabled', false);
        $('#address2_same').prop('checked', 'checked');
        updateCheckboxAddress2();
    }


    function getPerson(person_id){
        if(person_id){
            $.getJSON('/api/persons/' + person_id, function (data) {
                console.log(data);
                updateForm(data)
            }).fail(function () {
                cleanForm();
            });
        } else {
            cleanForm();
        }
    }

    function updateForm(data) {
        $('#address2_same').prop('checked', null);
        updateCheckboxAddress2();
        document.getElementById('firstname').value = data.firstname;
        document.getElementById('lastname').value = data.lastname;
        document.getElementById('birthdate').value = data.birthdate;
        document.getElementById('birthplace').value = data.birthplace;
        document.getElementById('gender').value = data.gender;
        if(document.getElementById('pesel')) document.getElementById('pesel').value = data.pesel;
        document.getElementById('email').value = data.email;
        document.getElementById('phone').value = data.phone;
        document.getElementById('address_city').value = data.address[0].city;
        document.getElementById('address_street').value = data.address[0].street;
        document.getElementById('address_number').value = data.address[0].number;
        document.getElementById('address_postcode').value = data.address[0].postcode;
        document.getElementById('address2_city').value = data.address[1].city;
        document.getElementById('address2_street').value = data.address[1].street;
        document.getElementById('address2_number').value = data.address[1].number;
        document.getElementById('address2_postcode').value = data.address[1].postcode;
        $("input[type=text]").prop('readonly', true);
        $("input[type=number]").prop('readonly', true);
        $("input[type=email]").prop('readonly', true);
        $("#gender").prop('disabled', true);

        $('#address2_same').prop('disabled', true);
        document.getElementById('button_update_person').style.display = 'block';
    }

    function updateCheckboxAddress2(){
        if($('#address2_same').prop('checked')){
            $('#address2').css('display','none');
            $('.address2-input').each(function(){
                $(this).removeAttr('required');
            });
        } else {
            $('#address2').css('display','block');
            $('.address2-input').each(function(){
                $(this).attr('required','required');
            });
        }
    }

    function updateCheckboxAddress3() {
        if ($('#address3_same').prop('checked')) {
            $('#address3').css('display', 'none');
            $('.address3-input').each(function () {
                $(this).removeAttr('required');
            });
        } else {
            $('#address3').css('display', 'block');
            $('.address3-input').each(function () {
                $(this).attr('required', 'required');
            });
        }
    }

  jQuery.validator.addMethod("pesel", function(value, element) {
    var pesel = value.replace(/[\ \-]/gi, '');
    if (pesel.length != 11) { return false; } else {
      var steps = new Array(1, 3, 7, 9, 1, 3, 7, 9, 1, 3);
      var sum_nb = 0;
      for (var x = 0; x < 10; x++) { sum_nb += steps[x] * pesel[x];}
      sum_m = 10 - sum_nb % 10;
      if (sum_m == 10) { sum_c = 0; } else { sum_c = sum_m;}
      if (sum_c != pesel[10]) {	return false;}
    }
    return true;
  }, "Proszę o podanie prawidłowego numeru PESEL");

    jQuery.validator.addMethod("zipcode", function(value, element) {
        return /[0-9]{2}-[0-9]{3}/.test(value);
    }, "Proszę o podanie prawidłowego kodu pocztowego.");

    jQuery.validator.addMethod("younger_than", function(value, element) {


        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1; //January is 0!

        var yyyy = today.getFullYear();
        if(dd<10){
            dd='0'+dd;
        }
        if(mm<10){
            mm='0'+mm;
        }
        var today_format = mm+'-'+dd+'-'+yyyy;


        value = value.split("-");
        value = value[2]+'-'+value[1]+'-'+value[0];

        today_date = new Date(today_format).getTime();
        birthday_date = new Date(value).getTime();


        if(birthday_date >= today_date){
            return false;
        } else {
            return true;
        }

        return console.log(value, element);
    }, "Data urodzenia nie może być większa niż obecna.");


    jQuery.validator.addMethod("older_than", function(value, element) {

        value = value.split("-");
        value = value[1]+'-'+value[0]+'-'+value[2];

        mature_date = new Date(new Date(new Date().setFullYear(new Date().getFullYear() - 18))).getTime();
        birthday_date = new Date(value).getTime();


        if(birthday_date > mature_date){
            return false;
        } else {
            return true;
        }

        return console.log(value, element);
    }, "Opiekun musi być pełnoletni.");

    jQuery.validator.addMethod("min_participant_age", function(value, element) {

        value = value.split("-");
        value = value[1]+'-'+value[0]+'-'+value[2];

        min_age = new Date(new Date(new Date().setFullYear(new Date().getFullYear() - 6))).getTime();
        birthday_date = new Date(value).getTime();

        console.log(min_age, birthday_date)

        if(birthday_date > min_age){
            return false;
        } else {
            return true;
        }

        return console.log(value, element);
    }, "Uczestnik musi mieć przynajmniej 6 lat.");


    jQuery.validator.addMethod("pesel_validate", function(value, element) {

        var birthdate = document.getElementById('birthdate').value;

        if(birthdate.length) {

            //y - m -d
             birthdate = birthdate.split("-");
             birthdate = birthdate[2] + '-' + birthdate[1] + '-' + birthdate[0];

            var year = parseInt(value.substring(0, 2), 10);
            var month = parseInt(value.substring(2, 4), 10);
            var day = parseInt(value.substring(4, 6), 10);
            if (month > 80) {
                year += 1800;
                month = month - 80;
            }
            else if (month > 60) {
                year += 2200;
                month = month - 60;
            }
            else if (month > 40) {
                year += 2100;
                month = month - 40;
            }
            else if (month > 20) {
                year += 2000;
                month = month - 20;
            }
            else {
                year += 1900;
            }

            if(day < 10 ) {
                day = '0' + day
            }
            if( month < 10){
                console.log('in if month')
                month =  '0' + month
            }


            var pesel_date = moment(year + '-' + month + '-' + day).unix();

            console.log(year + '-' + month + '-' + day);

            //pesel_date = new Date(year + '-' + month + '-' + day).getTime();
             var birthdate = moment(birthdate).unix();

            console.log(pesel_date, birthdate);

            if(pesel_date === birthdate) {
                return true;
            }


            return false;
        }


        return false;
    }, "Pesel musi zgadzać się z datą urodzenia.");

  $(document).ready(function(){

      $("#person_form").validate({
          lang: app_lang,
          rules: {
            phone: {
              minlength: 9
            },
          },
          submitHandler: function(form) {
              //$("#register-btn").prop('disabled','disabled');
              form.submit();
          }
      });

      $('#birthdate').datepicker({
          format: 'dd-mm-yyyy',
          startView: 'years',
          language: app_lang
      });

      $('#address2_same').change(function(){
          updateCheckboxAddress2();
      });

      $('#address3_same').change(function(){
          updateCheckboxAddress3();
      });

      if($("#person-id").length) {

          getPerson(parseInt($("#person-id").val()));
      }


      $("#person-id").change(function(){
          getPerson( parseInt($("#person-id").val()) );
      });

  });


}

