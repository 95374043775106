var jobs_docs_idx = 2;

$(document).ready(function(){

    function prepareForm(){
        $(".upload-doc-box-file-input").unbind('change');
        $(".upload-doc-box-file-input").change(function() {
            if(this.files.length){
                console.log(this.files[0].name);
                $(this).closest('.upload-doc').addClass('has-file');
                $(this).closest('.upload-doc').find('.upload-doc-box-file-name').val(this.files[0].name);
            } else {
                $(this).closest('.upload-doc').find('.upload-doc-box-file-name').val('');
            }
        });
        $(".upload-doc-delete").unbind('click');
        $(".upload-doc-delete").click(function(){
            $(this).closest('.upload-doc').remove();
        });
    }

    function addJobsDocument(){
        var upload_doc_html = '\
            <div class="box-panel upload-doc" data-doc-id="'+jobs_docs_idx+'"> \
                <div class="form-group"> \
                    <label class="col-sm-4 control-label">Nazwa dokumentu</label> \
                    <div class="col-sm-8 input-wrapper"> \
                    <input type="text" class="form-control" value="" placeholder="Zaświadczenie / Książeczka Sanepidu itp." name="name['+jobs_docs_idx+']" required> \
                    <input type="hidden" value="2" name="document_type['+jobs_docs_idx+']"> \
                    </div> \
                </div> \
                <div class="form-group"> \
                    <label class="col-sm-4 control-label">Plik</label> \
                    <div class="col-sm-8 input-wrapper"> \
                    <div class="input-group upload-doc-box"> \
                        <input type="text" class="form-control upload-doc-box-file-name" id="file-'+jobs_docs_idx+'-file-name"/> \
                        <input type="file" class="upload-doc-box-file-input" name="file['+jobs_docs_idx+']" accept="application/pdf, image/*" required/> \
                        <span class="input-group-btn"> \
                            <button class="btn btn-default">Wybierz plik</button> \
                        </span> \
                    </div> \
                    </div> \
                </div> \
                <div class="form-group"> \
                    <div class="col-sm-2 col-sm-offset-10"> \
                        <a class="btn btn-danger btn-sm upload-doc-delete">Usuń</a> \
                    </div> \
                </div> \
            </div> \
        ';
        $('#jobs-cv-form-wrapper').append(upload_doc_html);

        prepareForm();

        jobs_docs_idx++;
    }

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        }
    });

    function jobsDocsAjaxUplod(){
        $("#jobs-documents-form").find('input').attr('readonly', 'readonly');
        $("#jobs-documents-form").find('button').attr('disabled', 'disabled');
        $("#jobs-documents-form").find('.upload-doc-box-file-input').width('0px');
        $("#jobs-documents-form").find('.upload-doc-delete').css('display','none');
        $("#jobs-form-control").css('display', 'none');
        $("#jobs-form-progress").css('display', 'block');
        var form_data = new FormData(document.forms['documents']);
        $.ajax({
            url: $("#jobs-documents-form").attr('action'),
            type: 'POST',
            xhr: function() {
                var xhr = $.ajaxSettings.xhr();
                if (xhr.upload) {
                    xhr.upload.addEventListener('progress', function(evt) {
                        var percent = (evt.loaded / evt.total) * 100;
                        $("#jobs-form-progress-bar").width(percent+'%');
                        console.log("Upload progress: " + percent + "%");
                    }, false);
                }
                return xhr;
            },
            success: function(data) {
                console.log(data);
                if (!data.error) {
                    console.log("success");
                    setTimeout(function(){
                        document.location.href = "/jobs/apply/success";
                    }, 1000);
                } else {
                    alert(data.error);
                    location.reload();
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                alert("Wystąpił błąd!\n" + textStatus + "\n" + errorThrown);
                console.warn(errorThrown);
            },
            data: form_data,
            contentType: false,
            timeout: 0,
            processData: false
        }, 'json');
    }

    $("#jobs-cv-form-add-file").click(function(){
        addJobsDocument();
    });

    $("#jobs-documents-form").validate({
        errorPlacement: function(error, element) {
            error.appendTo( element.closest('.input-wrapper') );
        },
        submitHandler: function(form) {
            console.log('RUN!');
            jobsDocsAjaxUplod();
        }
    });

    prepareForm();
    //addJobsDocument();

    $("#jobs-position-form").validate({
      rules: {
          trips: {
              required: true,
              minlength: 1
          }
       },
       messages: {
            trips: {
                minlength: jQuery.validator.format("Musisz wybrać conajmniej {0} wyjazd!"),
            },
            position: {
                required: "Musisz wybrać stanowisko o jakie się ubiegasz!",
            }
       },
       errorPlacement: function(error, element) {
            error.appendTo( element.closest(".input-group"));
       }
   });

   $("#jobs-person-form").validate();

   $("#jobs-profile-documents-form").validate({
        errorPlacement: function(error, element) {
            error.appendTo( element.closest('.input-wrapper') );
        },
        submitHandler: function(form) {
            console.log('RUN!');
            form.submit();
        }
    });

   
});
