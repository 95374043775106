
if(window.page_name == 'invoice'){


  $(document).ready(function(){

    $("#invoice-form").validate({
      submitHandler: function(form) {
          //$("#register-btn").prop('disabled','disabled');
          form.submit();
      }
    });

  });

}
