$(document).ready(function(){
  $("#password-change-form").validate({
      lang: app_lang,
      rules: {
        password_new: {
          minlength: 6
        },
        password_new_confirmation: {
          equalTo: "#password_new",
        }
      },
      messages: {
        password_new: {
          minlength: "Hasło musi składać się conajmniej z 6 znaków!",
        },
        password_new_confirmation: {
          equalTo: "Podane hasła nie są identyczne!",
        }
      }
    });
});
