if (window.page_name == 'reservation') {
    $(document).ready(function(){
        const state = {
            click: () => state.clicked = true,
            clicked: false,
            apiUrl: $('#state-data').attr("data-app-url"),
            reservationId: $('#state-data').attr("data-reservation-id"),
            csrfToken: $('#state-data').attr("data-csrf"),
            cancelText: $('#state-data').attr("data-cancel-text"),
        };

        const createCsrfFromToken = (token) => {
            const csrfSecurity = document.createElement("input");

            csrfSecurity.type = "hidden";
            csrfSecurity.name = "_token";
            csrfSecurity.value = token;

            return csrfSecurity
        };

        const createForm = ({action, id, method}) => {
            const form = document.createElement("form");
            form.method = method;
            form.id = id;
            form.action = action;
            return form;
        };

        const cancelReservation = () => {
            if (state.clicked) return;

            const reason = prompt(state.cancelText);

            if (reason == null) {} else {
                const form = createForm({
                    action: `${state.apiUrl}/account/reservations/${state.reservationId}/cancel`,
                    id: "cancel-reservation-form",
                    method: 'POST'
                });

                const reasonDOM = document.createElement("input");
                reasonDOM.value = reason;
                reasonDOM.name = "reason";

                form.appendChild(reasonDOM);
                form.appendChild(createCsrfFromToken(state.csrfToken));

                document.body.appendChild(form);

                form.submit();
                state.click();
            }
        };

        const acceptReserve = () => {
            if (state.clicked) return;

            const form = createForm({
                method: "POST",
                id: "checkout-reserve-to-full-reservation-form",
                action: `${state.apiUrl}/account/reservations/${state.reservationId}/initializeFromCheckoutState`
            });

            form.appendChild(createCsrfFromToken(state.csrfToken));
            document.body.appendChild(form);

            form.submit();
            state.click()
        };

        const buttonBindings = () => {
            $('#cancel-reservation-button').click(cancelReservation);
            $('#checkout-reserve-to-full-reservation-button').click(acceptReserve)
        };

        const componentDidMount = () => {
            buttonBindings()
        };

        componentDidMount();
    });
}
