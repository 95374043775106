
if(window.page_name == 'summary'){

  $(document).ready(function(){
    const state = {
        isParticipantDuplicated: $('#state-data').attr("data-is-participant-duplicated"),
    }

    $("#summary-form").validate({
      lang: app_lang,
      submitHandler: function(form) {
          if(state.isParticipantDuplicated) {
              if(confirm(window.participant_duplicated_translation)) {
                  $("#summary-confirm-btn").prop('disabled','disabled');
                  form.submit();
              }
          } else {
              $("#summary-confirm-btn").prop('disabled','disabled');
              form.submit();
          }
      }
    });
  });

}
