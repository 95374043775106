if(window.page_name == 'reserve'){
  $(document).ready(function(){
    const state = {
      participant_count: 0
    };

    const addParticipant = (e) => {
      e.preventDefault();
      state.participant_count = state.participant_count + 1;

      const newParticipantForm = $("#participant-form-0").clone();
      newParticipantForm.attr("id", `participant-form-${state.participant_count}`);

      newParticipantForm.children().each(function() {
        if($(this).hasClass('participant-title')) {
          $(this).text($(this).text().replace(/\ \d+/g, ` ${state.participant_count + 1}`));
          return;
        }
        $(this).children().each(function() {
          $(this).children().each(function() {
            $(this).attr('name', $(this).attr('name').replace(/\[\d+\]/g, `[${state.participant_count}]`))
            $(this).attr('id', $(this).attr('id').replace(/\-\d+/g, `-${state.participant_count}`))
            $(this).val(null)
          })
        })
      });

      newParticipantForm.appendTo("#participant-forms");
      datePickerForParticipant(state.participant_count);
    };

    const removeParticipant = (e) => {
      if (state.participant_count < 1) return;
      e.preventDefault();
      $(`#participant-form-${state.participant_count}`).remove();
      state.participant_count = state.participant_count - 1;
    };

    const buttonBindings = () => {
      $('#add-new-participant-button').click(addParticipant);
      $('#remove-participant-button').click(removeParticipant)
    };

    const datePickerForParticipant = (participant) => {
      $(`#birthdate-${participant}`).datepicker({
        format: 'yyyy-mm-dd',
        startView: 'years',
        language: app_lang
      });
    };

    const componentDidMount = () => {
      datePickerForParticipant(0);
      buttonBindings();
    };

    componentDidMount();
  })
}
