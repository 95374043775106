var jobs_docs_idx = 2;

$(document).ready(function(){

    $('#job-profile-iban-form').validate({
        errorPlacement: function(error, element) {
            error.appendTo( element.closest('.input-wrapper') );
        },
        rules: {
            iban: {
                required: true,
                iban: true
            }
        }
    });

    $('#job-profile-clothing-size-form').validate({
        errorPlacement: function(error, element) {
            error.appendTo( element.closest('.input-wrapper') );
        }
    });

    function uploadFormUpdate(){
        $('.job-profile-documents-upload-trip').css('display', 'none');
        var trip_id = $('#job-profile-documents-trip-id').val();
        $('#job-profile-documents-upload-trip-'+trip_id).css('display', 'block');
    }

    $('#job-profile-documents-trip-id').change(function(){
        uploadFormUpdate();
    });
    
    uploadFormUpdate();

    $('.job-profile-send-doc-btn').click(function(){
        $('#job-profile-send-form-doc-type-name').val( $(this).data('document-type-name') );
        $('#job-profile-send-form-doc-type-id').val( $(this).data('document-type-id') );
        $('#job-profile-send-form-trip-name').val( $(this).data('trip-name') );
        $('#job-profile-send-form-trip-id').val( $(this).data('trip-id') );
        
        $('#job-profile-send-doc-modal').modal();
    });
    

   
});
